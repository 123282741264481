import request from 'graphql-request';
import { GRAPHQL_ENDPOINT } from '../../pages/_app';

export const HightScorePhysiciansDocument = `
query HighScorePhysicians($hospitalId: String, $healthSystemId: String, $size: Int, $page: Int) {
  highScorePhysicians(
    hospitalId: $hospitalId
    healthSystemId: $healthSystemId
    size: $size
    page: $page
  ) {
    physicianId
    score
    prescriber
    monthlyMedicationSpend
    monthlyPatientCensus
    status
    date
    id
  }
}

`;

export function getHighScorePhysicians(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    HightScorePhysiciansDocument,
    queryVariables,
    headers
  );
}

export const PhysicianAnalysisDocument = `
query PhysicianAnalysisCard(
  $physicianId: String!
  $normalizedDrgs: [String]
  $startDate: String
  $endDate: String
  $hospitalIds: [String]
) {
  physicianAnalysisCard(
    physicianId: $physicianId
    normalizedDrgs: $normalizedDrgs
    startDate: $startDate
    endDate: $endDate
    hospitalIds: $hospitalIds
  ) {
    physicianName
    monthlyDrugSpend
    drugSpendPerPatient
    monthlyPatient
    averageLengthOfStay
    medicationExpenseScore
    mesRangeUpper
    mesRangeLower
    drugSpendPerPatientRange {
      q_ten
      q_ninty
    }
    averageLengthOfStayRange {
      q_ten
      q_ninty
    }
  }
}
`;

export function getPhysiciansAnalysis(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    PhysicianAnalysisDocument,
    queryVariables,
    headers
  );
}

export const PhysicianPerformanceDocument = `
    query($physicianId: String!) {
        physicianPerformanceCard(physicianId: $physicianId) {
            medicationExpenseScore {
                metricValue
                rangeLower
                rangeUpper
              }
              theraputicPerformance {
                theraputicName
                metricValue
                rangeLower
                rangeUpper
                normalizedDrg
                modelId
              }
        }
    }
`;

export function getPhysiciansPerformance(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    PhysicianPerformanceDocument,
    queryVariables,
    headers
  );
}

export const TherapeuticNameByPhysicanIdQuery = `
query PhysicianPerformanceCard($physicianId: String!, $date: String) {
  physicianPerformanceCard(physicianId: $physicianId, date: $date) {
    theraputicPerformance {
      theraputicName
      normalizedDrg
    }
  }
}

`;

export function getTherepeuticNameByPhysicianId(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    TherapeuticNameByPhysicanIdQuery,
    queryVariables,
    headers
  );
}

export const PhysicianUtilizationDocument = `
    query($physicianId: String!,$normalizedDrgs: [String], $hospitalIds: [String]) {
      monthlySpendPerTherapeuticPerPhysician(
        physicianId: $physicianId
        normalizedDrgs: $normalizedDrgs
        hospitalIds: $hospitalIds
      ) {
        therapeutic
        longDescription
        spend
      }
      monthlySpendPerMedicationPerPhysician(
        physicianId: $physicianId
        normalizedDrgs: $normalizedDrgs
        hospitalIds: $hospitalIds
      ) {
        medication
        spend
      }
      patientCensusPerMedicationPerPhysician(
        physicianId: $physicianId
        normalizedDrgs: $normalizedDrgs
        hospitalIds: $hospitalIds
      ) {
        patientCensus
        medication
      }
      patientCensusPerTherapeuticPerPhysician(
        physicianId: $physicianId
        normalizedDrgs: $normalizedDrgs
        hospitalIds: $hospitalIds
      ) {
        patientCensus
        therapeutic
        longDescription
      }
    }
`;

export function getPhysicianUtilization(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    PhysicianUtilizationDocument,
    queryVariables,
    headers
  );
}

export const PhysicianTrendDocument = `
query PhysicianTrend(
  $physicianId: String!
  $frequency: String!
  $normalizedDrgs: [String]
  $hospitalIds: [String]
) {
  physicianTrend(
    physicianId: $physicianId
    frequency: $frequency
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
  ) {
    startDate
    endDate
    chart {
      name
      chartType
      data {
        value
        date
      }
    }
  }
}
`;

export function getPhysicianTrend(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    PhysicianTrendDocument,
    queryVariables,
    headers
  );
}

const PhysicianCompareDocument = `
query PhysicianCompare(
  $physician1: String!
  $physician2: String!
  $normalizedDrgs: [String]
  $hospitalIds: [String]
  $size: Int
  $page: Int
) {
  medicationMedSpendCompare(
    physician1: $physician1
    physician2: $physician2
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
    size: $size
    page: $page
  ) {
    title
    subtitle
    before
    after
  }
  generalPhysicianCompare(
    physician1: $physician1
    physician2: $physician2
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
  ) {
    title
    subtitle
    before
    after
  }
  therapeuticPatientCensusCompare(
    physician1: $physician1
    physician2: $physician2
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
    size: $size
    page: $page
  ) {
    title
    subtitle
    before
    after
  }

  therapeuticMedSpendCompare(
    physician1: $physician1
    physician2: $physician2
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
    size: $size
    page: $page
  ) {
    title
    subtitle
    before
    after
  }
  therapeuticMedSpendPerPatientCompare(
    physician1: $physician1
    physician2: $physician2
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
    size: $size
    page: $page
  ) {
    title
    subtitle
    before
    after
  }
  medicationPatientCensusCompare(
    physician1: $physician1
    physician2: $physician2
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
    size: $size
    page: $page
  ) {
    title
    subtitle
    before
    after
  }
  medicationMedSpendPerPatientCompare(
    physician1: $physician1
    physician2: $physician2
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
    size: $size
    page: $page
  ) {
    title
    subtitle
    before
    after
  }
}
`;

export function getPhysicianCompare(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    PhysicianCompareDocument,
    queryVariables,
    headers
  );
}

const PhysicianCompareDocumentGeneral = `
query PhysicianCompare(
  $physician1: String!
  $physician2: String!
  $normalizedDrgs: [String]
  $hospitalIds: [String]
) {
  generalPhysicianCompare(
    physician1: $physician1
    physician2: $physician2
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
  ) {
    title
    subtitle
    before
    after
  }
}
`;

export function getPhysicianCompareGeneral(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    PhysicianCompareDocumentGeneral,
    queryVariables,
    headers
  );
}

const PhysicianCompareDocumentMedSpendCompare = `
query PhysicianCompare(
  $physician1: String!
  $physician2: String!
  $normalizedDrgs: [String]
  $hospitalIds: [String]
  $size: Int
  $page: Int
) {
  medicationMedSpendCompare(
    physician1: $physician1
    physician2: $physician2
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
    size: $size
    page: $page
  ) {
    title
    subtitle
    before
    after
  }
  medicationPatientCensusCompare(
    physician1: $physician1
    physician2: $physician2
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
    size: $size
    page: $page
  ) {
    title
    subtitle
    before
    after
  }
  medicationMedSpendPerPatientCompare(
    physician1: $physician1
    physician2: $physician2
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
    size: $size
    page: $page
  ) {
    title
    subtitle
    before
    after
  }
}
`;

export function getPhysicianMedSpendCompare(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    PhysicianCompareDocumentMedSpendCompare,
    queryVariables,
    headers
  );
}

const PhysicianCompareDocumentTherapeuticSpendCompare = `
query PhysicianCompare(
  $physician1: String!
  $physician2: String!
  $normalizedDrgs: [String]
  $hospitalIds: [String]
  $size: Int
  $page: Int
) {
  therapeuticPatientCensusCompare(
    physician1: $physician1
    physician2: $physician2
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
    size: $size
    page: $page
  ) {
    title
    subtitle
    before
    after
  }

  therapeuticMedSpendCompare(
    physician1: $physician1
    physician2: $physician2
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
    size: $size
    page: $page
  ) {
    title
    subtitle
    before
    after
  }
  therapeuticMedSpendPerPatientCompare(
    physician1: $physician1
    physician2: $physician2
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
    size: $size
    page: $page
  ) {
    title
    subtitle
    before
    after
  }
}
`;

export function getPhysicianTherapeuticSpendCompare(
  queryVariables,
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    PhysicianCompareDocumentTherapeuticSpendCompare,
    queryVariables,
    headers
  );
}

const PhysicianOptionsDocument = `
  query PhysicianOption {
    physicianOption {
      label
      value
    }
  }`;

export function getPhysicianOptions(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    PhysicianOptionsDocument,
    queryVariables,
    headers
  );
}

export const PhysicianTheraputicMoreCards = `
query PhysicianTheraputicMoreCards(
  $physicianId: String!
  $normalizedDrg: String!
  $date: String
) {
  PhysicianTheraputicMoreCards(
    physicianId: $physicianId
    date: $date
    normalizedDrg: $normalizedDrg
  ) {
    metricValue
    medicationName
    theraputicCost
    theraputicName
    rangeLower
    rangeUpper
  }
}
`;

export function getPhysicianTheraputicMoreCardsDetails(
  queryVariables,
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    PhysicianTheraputicMoreCards,
    queryVariables,
    headers
  );
}

export const PhysicianPatientBreakdown = `query PatientVolumeBreakdown(
  $physicianId: String!
  $normalizedDrgs: [String]
  $hospitalIds: [String]
  $size: Int
  $page: Int
  $startDate: String
  $endDate: String
) {
  patientVolumeBreakdown(
    physicianId: $physicianId
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
    size: $size
    page: $page
    startDate: $startDate
    endDate: $endDate
  ) {
    therapeutic
    longDescription
    percentPhysician
    percentPeerGroup
  }
}

`;

export function getPhysicianPatientBreakDown(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    PhysicianPatientBreakdown,
    queryVariables,
    headers
  );
}

export const PrescriptionPatternAnalysisMedication = `
query PrescriptionPatternAnalysisMedicationPrescriptionRate(
  $physicianId: String!
  $normalizedDrgs: [String]
  $hospitalIds: [String]
  $modelOutlierId: String
  $size: Int
  $page: Int
  $startDate: String
  $endDate: String
) {
  prescriptionPatternAnalysisMedicationPrescriptionRate(
    physicianId: $physicianId
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
    modelOutlierId: $modelOutlierId
    size: $size
    page: $page
    startDate: $startDate
    endDate: $endDate
  ) {
    medPrescriptionRate
    genericName
    q_ten
    q_ninty
  }
}
`;

export function getPrescriptionPatternAnalysisMedication(
  queryVariables,
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    PrescriptionPatternAnalysisMedication,
    queryVariables,
    headers
  );
}

export const PrescriptionPatternAnalysisPerPatientCost = `
query prescriptionPatternAnalysis(
  $physicianId: String!
  $normalizedDrgs: [String]
  $hospitalIds: [String]
  $modelOutlierId: String
  $startDate: String
  $endDate: String
  $page: Int!
  $size: Int!
) {
  prescriptionPatternAnalysis(
    physicianId: $physicianId
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
    modelOutlierId: $modelOutlierId,
    startDate: $startDate,
    endDate: $endDate,
    page: $page,
    size: $size
  ) {
    genericName
    medPrescriptionRateLwr
    medPrescriptionRateUpr
    medicationPrescriptionRate
    perPatientCost
    perPatientCostLwr
    perPatientCostUpr
  }
}

`;

export function getPrescriptionPatternAnalysisPerPatientCost(
  queryVariables,
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    PrescriptionPatternAnalysisPerPatientCost,
    queryVariables,
    headers
  );
}

export const TopMedicationSpendTableQuery = `query TopMedicationSpendTable(
  $physicianId: String!
  $normalizedDrgs: [String]
  $hospitalIds: [String]
  $modelOutlierId: String
  $page: Int
  $size: Int
  $startDate: String
  $endDate: String
) {
  topMedicationSpendTable(
    physicianId: $physicianId
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
    modelOutlierId: $modelOutlierId
    page: $page
    size: $size
    startDate: $startDate
    endDate: $endDate
  ) {
    physicianSpendRatio
    genericName
    medSpendRatio
    monthlySpend
    patientVolume
    drugPrice
  }
}

`;

export function getTopMedicationSpendTable(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    TopMedicationSpendTableQuery,
    queryVariables,
    headers
  );
}

export const TopTherapeuticsTableQuery = `query TopTherapeuticSpendTable(
  $physicianId: String!
  $normalizedDrgs: [String]
  $hospitalIds: [String]
  $modelOutlierId: String
  $page: Int
  $size: Int
  $startDate: String
  $endDate: String
) {
  topTherapeuticSpendTable(
    physicianId: $physicianId
    normalizedDrgs: $normalizedDrgs
    hospitalIds: $hospitalIds
    modelOutlierId: $modelOutlierId
    page: $page
    size: $size
    startDate: $startDate
    endDate: $endDate
  ) {
    physicianSpendRatio
    title
    longDescription
    therapeuticRatio
    monthlySpend
    patientVolume
  }
}
`;

export function getTopTherapeuticsTable(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    TopTherapeuticsTableQuery,
    queryVariables,
    headers
  );
}
