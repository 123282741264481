import { Pagination } from '@mui/material';

const CustomPagination = prop => {
  const props = prop.props;
  const handleChangePage = (event, newPage) => {
    if (props?.customPagination) {
      if (props?.setPaginationChanges) {
        props?.setPaginationChanges(true);
      }
      return props?.setPage(newPage);
    }
    if (props?.table) {
      props?.table?.setPageIndex(newPage - 1);
    } else {
      props?.onPageChange(event, newPage - 1);
    }
  };
  const count = props?.customsNextPaginationEnabled || props?.customsNextPaginationEnabled==undefined
    ? Math.ceil(
        (props?.count || prop?.count) /
          (props?.rowsPerPage || prop?.rowsPerPage)
      )
    : 1;

  return (
    <span
      style={{
        display: 'flex',
        justifyContent: 'end'
        // margin: '20px 0px'
      }}
    >
      <Pagination
        count={count || 1}
        page={props.page + 1}
        onChange={handleChangePage}
        rowsPerPage={props?.rowsPerPage || prop?.rowsPerPage}
        variant="outlined"
        color="primary"
        boundaryCount={1}
        defaultPage={1}
        sx={{
          '& .MuiPaginationItem-root': {
            border: 'none',
            color: '#387ED0'
          },
          '& .MuiPaginationItem-root.Mui-selected': {
            backgroundColor: '#387ED0',
            color: '#fff'
          },
          '& .MuiPagination-root': {
            '& .MuiPaginationItem-root:hover': {
              background: '#f5f5f5',
              boxShadow: '0px 0px 10px #ccc'
            }
          },
          '& .MuiPaginationItem-previousNext': {
            background: '#F3F8FF'
          }
        }}
      />
    </span>
  );
};

export default CustomPagination;
