export const formatCurrency = (number, digits = 0) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return number >= item.value;
    });
  return item
    ? (number / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
};

export const getFormattedNumber = (num, fraction = 1) => {
  const number = parseFloat(num) || 0;
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: fraction
  }).format(number);
};

export function getPositionOfFirstDigit(value) {
  if (value < 1) {
    const stringValue = value.toString();
    for (let i = 0; i < stringValue.length; i++) {
      if (parseInt(stringValue[i]) > 0) {
        return i - 1;
      }
    }
  }
  return 1;
}
