export const TableHeading = ({ title, Icon, marginTop = '3px',customStyle={}, ...props }) => {
  return (
    <span
      style={{
        position: 'relative',
        height: '100%',
        width: '100%'
      }}
    >
      {title}
      {Icon && (
        <span
          style={{
            paddingLeft: '4px',
            marginTop: marginTop,
            ...customStyle
          }}
        >
          <Icon />
        </span>
      )}
    </span>
  );
};
  